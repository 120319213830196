//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import PlaceListTable from '@/modules/place/components/place-list-table.vue';
import { PlacePermissions } from '@/modules/place/place-permissions';

export default {
  name: 'app-place-list-page',

  components: {
    [PlaceListTable.name]: PlaceListTable,
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    hasPermissionToCreate() {
      return new PlacePermissions(this.currentUser).create;
    },
  },
};
