// import { i18n } from '@/i18n';
import { i18n } from '@/vueI18n'
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import IntegerField from '@/shared/fields/integer-field';
import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import ImagesField from '@/shared/fields/images-field';
import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
// import JsonField from '@/shared/fields/json-field';
// import { PlaceTypeField } from '@/modules/placeType/placeType-field';
// import { CityField } from '@/modules/city/city-field';

function label(name) {
  return i18n(`entities.place.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.placeType.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  placeName: new JsonField('placeName', label('placeName'), {}),
  description: new JsonField('description', label('description'), {}),
  cityId: new StringField('cityId', label('city'), {}),
  countryId: new StringField('countryId', label('country'), {}),
  placeType: new EnumeratorField('placeType', label('placeType'), [
    { value: 'mosque', label: enumeratorLabel('placesType', 'mosque'), i18n: 'entities.placeType.enumerators.placesType.mosque' },
    { value: 'museum', label: enumeratorLabel('placesType', 'museum'), i18n: 'entities.placeType.enumerators.placesType.museum' },
    { value: 'playground', label: enumeratorLabel('placesType', 'playground'), i18n: 'entities.placeType.enumerators.placesType.playground' },
    { value: 'event', label: enumeratorLabel('placesType', 'events'), i18n: 'entities.placeType.enumerators.placesType.events' },
    { value: 'mountain', label: enumeratorLabel('placesType', 'mountains'), i18n: 'entities.placeType.enumerators.placesType.mountains' },
    { value: 'market', label: enumeratorLabel('placesType', 'market'), i18n: 'entities.placeType.enumerators.placesType.market' },
    { value: 'cafe', label: enumeratorLabel('placesType', 'cafe'), i18n: 'entities.placeType.enumerators.placesType.cafe' },
  ],{}),
  placeTypeAsEnum: new EnumeratorField('placeType', label('placeType'), [
    // { value: '/images/icon-uploader.svg', icon: '--', label: enumeratorLabel('placesType', 'clear') },
    { value: 'mosque', icon: '/images/placeType/1.svg', label: enumeratorLabel('placesType', 'mosque') },
    { value: 'museum', icon: '/images/placeType/2.svg', label: enumeratorLabel('placesType', 'museum') },
    { value: 'playground', icon: '/images/placeType/3.svg', label: enumeratorLabel('placesType', 'playground') },
    { value: 'event', icon: '/images/placeType/4.svg', label: enumeratorLabel('placesType', 'events') },
    { value: 'mountain', icon: '/images/placeType/5.svg', label: enumeratorLabel('placesType', 'mountains') },
    { value: 'market', icon: '/images/placeType/6.svg', label: enumeratorLabel('placesType', 'market') },
    { value: 'cafe', icon: '/images/placeType/7.png', label: enumeratorLabel('placesType', 'cafe') },
  ],{}),

  visitCount: new IntegerField('visitCount', label('visitCount'), {}),
  placePictures: new ImagesField('placePictures', label('placePictures'),
    '/place/image',
  ),
  lat: new DecimalField('lat', label('lat'), {
    "required": true,
    // "scale": 2,
    "min": -90,
    "max": 90
  }),
  lng: new DecimalField('lng', label('lng'), {
    "required": true,
    // "scale": 2,
    "min": -180,
    "max": 180
  }),
  radius: new DecimalField('radius', label('radius'), {}),
  strokeWidth: new DecimalField('strokeWidth', label('strokeWidth'), {}),
  strokeColor: new StringField('strokeColor', label('strokeColor'), {}),
  fillColor: new StringField('fillColor', label('fillColor'), {}),
  address: new JsonField('address', label('address'), {}),
  notifications: new JsonField('notifications', label('notifications'), {}),
  title: new StringField('title', label('title')),
  message: new StringField('message', label('message')),

  radiusRange: new IntegerRangeField('radiusRange', label('radiusRange')),
  strokeWidthRange: new IntegerRangeField('strokeWidthRange', label('strokeWidthRange')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class PlaceModel extends GenericModel {
  static get fields() {
    return fields;
  }
}